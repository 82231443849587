import { Button, Card, Link } from "@mui/material";
import React, { useCallback, useEffect, useState } from 'react';
import {useLocation} from "react-router-dom";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { PermanentReplacementStatus } from "../../enums/PermanentReplacementStatus";
import EventBus from "../../EventBus";
import { AdminUserInterface } from "../../interfaces/Admin/AdminUserInterface";
import { TrialResponse } from "../../interfaces/Trials/TrialsResponse";
import { UserBundlesResponse } from "../../interfaces/UserBundleMapping/UserBundlesResponse";
import UserBundleService from '../../services/BundleService';
import PermanentReplacementService from "../../services/PermanentReplacementService";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import AdminUserList from "../AdminUser/AdminUserList";
import CreateTrForPrDialog from "../Common/CreateTrForPrDialog";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import MarkFailedPRDialog from "../Common/MarkFailedPRDialog";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import TrialsListForModule from "../Trials/TrialsListForModule";
import PRSelectDateSlotDialog from "./PRSelectDateSlotDialog";
import {GetListResult} from "react-admin";
import {SubscriptionResponse} from "../../interfaces/UserBundleMapping/SubscriptionForBundle";
import ConstructSequelizeFilters from "../../utils/ConstructSequelizeFilters";
import {CookSubscriptionResponse} from "../../interfaces/UserBundleMapping/CookSubscriptionForBundle";
import PRTrialCreateSuccessDialog from "./PRTrialCreateSuccessDialog";
import moment from "moment";
import CancelPRRequestDialog from "./CancelPRRequestDialog";
import {ReasonSubReasonResponse} from "../../interfaces/ReasonSubReason/ReasonSubReasonResponse";
import ReasonSubreasonServices from "../../services/ReasonSubreasonServices";
import {SlotsEnum} from "../../enums/SlotsEnum";

const permanentReplacementService = new PermanentReplacementService();
const userBundleMappingService = new UserBundleService();
const reasonSubReasonService = new ReasonSubreasonServices();

const PermanentReplacementShow = (props: any) => {
    let location = useLocation();
    let prId = Number(location.pathname.split('/')[2]);
    const dispatch = useAppDispatch();
    const [prDetails, setPrDetails] = useState<any>(null);
    const [trialsList, setTrialsList] = useState<Array<TrialResponse>>([]);
    const [createTrialDialog, setCreateTrialDialog] = useState<boolean>(false);
    const [markFailedDialog, setMarkFailedDialog] = useState<boolean>(false);
    const [userBundleMapping, setUserBundleMapping] = useState<UserBundlesResponse | null>(null);
    const prMarkFail = useAppSelector((state) => state.prMarkFailed.value)
    const [openPrSelectDateSlotDialog, setOpenPrSelectDateSlotDialog] = useState<boolean>(false);
    const [subscriptionDetails, setSubscriptionDetails] = useState<SubscriptionResponse>();
    const [allSubscriptionDetails, setAllSubscriptionDetails] = useState<SubscriptionResponse[]>();
    const [gracePeriodDetails, setGracePeriodDetails] = useState<any>({});
    const [cookSubscriptionDetails, setCookSubscriptionDetails] = useState<CookSubscriptionResponse>();
    const [openPrTrialCreatedSuccessDialog, setOpenPrTrialCreatedSuccessDialog] = useState<boolean>(false);
    const [openCancelPRDialog, setOpenCancelPRDialog] = useState<boolean>(false);
    const [prReasonSubreasonData, setPrReasonSubreasonData] = useState<ReasonSubReasonResponse[]>([]);
    const [refundDetail, setRefundDetail] = useState<any>();
    const getPRReasonSubReasonData = (module: string, actionType: string) => {
        reasonSubReasonService.fetchReasonAndSubReason(module, actionType)
            .then((res: any) => {
                if(res?.data && res?.data?.data?.length > 0) {
                    setPrReasonSubreasonData(res?.data?.data);
                }
            })
    }
    const handleCancelPRContinueClicked = (reason: string, subReason: string, comment: string, selectedOption: string) => {
        if(reason && subReason && selectedOption) {
            const payload = {
                "closureReason": reason,
                "closureSubreason": subReason,
                // "comments": comment,
                "wantsOldCookBack": selectedOption === 'wantsOldCookBack' ? true : false,
                "cancelSubscription": selectedOption === 'cancelSubscription' ? true : false,
            }
            permanentReplacementService.cancelPRRequest(prId, payload)
                .then(() => {
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                        message: 'Success',
                        type: CustomSnackbarTypes.SUCCESS
                    });
                    refresh();
                    // navigate(0);
                    setOpenCancelPRDialog(false);
                })
        }
    }
    const getRefundDetails = (userBundleMappingId: number, endDate: string, endSlot: SlotsEnum) => {
        userBundleMappingService?.getRefundDetails(userBundleMappingId, endDate, endSlot).then((res) => {
            if(res && res?.data?.length > 0) {
                setRefundDetail(res?.data[0]);
            }
        })
    }
    const refresh = useCallback(() => {
        permanentReplacementService.getAllTrialsForPermanentReplacement(prId)
            .then((res) => {
                setTrialsList(res);
            })
        permanentReplacementService.getPrDetailsById(prId)
            .then((res) => {
                setPrDetails(res);
                userBundleMappingService.getManyUserBundles([res.userBundleMappingId])
                    .then((userBundleMappingRes) => {
                        setUserBundleMapping(userBundleMappingRes.data[0]);
                        if(userBundleMappingRes?.data?.length > 0 && res?.status !== PermanentReplacementStatus.CLOSED) {
                            getRefundDetails(userBundleMappingRes?.data[0]?.id, userBundleMappingRes?.data[0]?.endDate, userBundleMappingRes?.data[0]?.endSlot as SlotsEnum);
                        }
                    })
                userBundleMappingService?.fetchAllSubscriptionsForUserBundleMapping(res?.userBundleMappingId, 100, 0, {order: "ASC", field: "startDate"})
                    .then((res: GetListResult<SubscriptionResponse>) => {
                        if(res?.data?.length > 0) {
                            setSubscriptionDetails(res?.data?.find((each) => each?.status === 'ACTIVE' || each?.status === 'GRACE'));
                            setAllSubscriptionDetails(res?.data);
                            setGracePeriodDetails(res?.data?.find((each) =>each?.type === 'GRACE'))
                        }
                    })
                let filters = ConstructSequelizeFilters({userBundleMappingId: res?.userBundleMappingId}, [], [], ['endDate'], []);
                userBundleMappingService?.fetchAllCookSubscriptionsForUserBundleMapping(res?.userBundleMappingId, 100, 0, {order: "DESC", field: "startDate"}, filters)
                    .then((res: any) => {
                        if(res?.data?.data?.length > 0) {
                            setCookSubscriptionDetails(res?.data?.data[0]);
                        }
                    })

            })
        getPRReasonSubReasonData('PERMANENT_REPLACEMENT', 'PR_CLOSURE');
    }, [prId])

    useEffect(() => {
        refresh()
    }, [refresh, prMarkFail])
    const handleCustomerWantCookBack = (value: boolean) => {
        EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
            message: value ? "Are you sure that the customer want the old cook back?" : "Are you sure you want to mark this Permanent Replacement Failure?",
            onClose: (confirmed: boolean) => {
                if (confirmed) {
                    permanentReplacementService.markPrFailure(prId, {wantsOldCookBack: value, closeUserBundle: false})
                        .then(() => {
                            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                message: 'Success',
                                type: CustomSnackbarTypes.SUCCESS
                            });
                            refresh();
                        })
                }
            },
            title: "Confirmation"
        });
    }

    const handleAdminOpenList = () => {
        dispatch(setCustomSelectOpen({
          open: true
        }));
      }

    const handleChangeOwner = (data: AdminUserInterface) => {
        permanentReplacementService.updatePrRequestowner(prId, data?.id).then(() => {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: 'Success',
                type: CustomSnackbarTypes.SUCCESS
            });
            refresh();
        })
    }

    const handleDateSlotContinueClicked = (selectedDate: string, selectedSlot: string, selectedOption: string) => {
        if(selectedDate && selectedSlot && prDetails) {
            if(allSubscriptionDetails?.some((e: any) => e.status === "ACTIVE" && e.type !== "GRACE")) {
                if(!selectedOption) {
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                        message: "Select a visit choice to proceed",
                        type: CustomSnackbarTypes.WARNING,
                    });
                    return;
                }
            }
            permanentReplacementService?.createPRTrial(prDetails?.id, selectedSlot, selectedDate, selectedOption, cookSubscriptionDetails?.id)
                .then((res) => {
                    if(res) {
                        setOpenPrSelectDateSlotDialog(false);
                        setOpenPrTrialCreatedSuccessDialog(true);
                    }
                }).catch((err) => {
                    setOpenPrSelectDateSlotDialog(false);
            })
        }
    }

    return (
        <Card
            style={{
                marginTop: '12px',
                overflow: 'auto'
            }}
        >
            <ResourceSelectDialog
                name={'AdminUsers'}
                list={AdminUserList}
                onClose={handleChangeOwner}
            />
            <CreateTrForPrDialog
                prId={prId}
                mealMapping={userBundleMapping?.userBundleMeta.mealMapping}
                open={createTrialDialog}
                callback={() => {
                    setCreateTrialDialog(false);
                    refresh();
                }}
            />
            {markFailedDialog && userBundleMapping &&
                <MarkFailedPRDialog
                    prId={prId}
                    open={markFailedDialog}
                    setOpen={setMarkFailedDialog}
                    userBundleMapping={userBundleMapping}
                />}
            {
                prDetails &&
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        margin: '12px'
                    }}
                >
                    {/*<Button*/}
                    {/*    variant={'contained'}*/}
                    {/*    size="small"*/}
                    {/*    color={'error'}*/}
                    {/*    style={{*/}
                    {/*        marginRight: '12px'*/}
                    {/*    }}*/}
                    {/*    onClick={()=> setMarkFailedDialog(true)}*/}
                    {/*>*/}
                    {/*    Mark Failed*/}
                    {/*</Button>*/}
                    { (prDetails?.status !== PermanentReplacementStatus.FAILED && prDetails?.status !== PermanentReplacementStatus.CLOSED && prDetails?.status !== PermanentReplacementStatus.SUCCESS) &&
                        <Button
                            variant={'contained'}
                            size="small"
                            color={'error'}
                            style={{
                                marginRight: '12px'
                            }}
                            onClick={() => {
                                if(prDetails?.status === PermanentReplacementStatus.INITIATED) {
                                    const payload = {};
                                    permanentReplacementService.cancelPRRequest(prId, payload)
                                        .then(() => {
                                            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                                message: 'Success',
                                                type: CustomSnackbarTypes.SUCCESS
                                            });
                                            refresh();
                                            // navigate(0);
                                            setOpenCancelPRDialog(false);
                                        })
                                }
                                else {
                                    setOpenCancelPRDialog(true)
                                }

                            }}
                        >
                            Cancel PR Request
                        </Button>
                    }
                    { prDetails.status === PermanentReplacementStatus.PENDING &&
                        <Button
                            variant={'contained'}
                            size="small"
                            color={'error'}
                            style={{
                                marginRight: '12px'
                            }}
                            onClick={() => handleCustomerWantCookBack(true)}
                        >
                            Customer wants old cook back
                        </Button>
                    }
                    {/*{(prDetails.status === PermanentReplacementStatus.PENDING || prDetails.status === PermanentReplacementStatus.INITIATED || prDetails.status === PermanentReplacementStatus.FAILED) &&*/}
                    {/*    <Button*/}
                    {/*        variant={'contained'}*/}
                    {/*        size="small"*/}
                    {/*        onClick={() => {*/}
                    {/*            setCreateTrialDialog(true);*/}
                    {/*        }}*/}
                    {/*        style={{*/}
                    {/*            marginRight: '12px'*/}
                    {/*        }}*/}
                    {/*>*/}
                    {/*        Create Trial for Replacement*/}
                    {/*    </Button>*/}
                    {/*}*/}
                    { prDetails.status !== PermanentReplacementStatus.FAILED &&
                        <Button
                            variant={'contained'}
                            size="small"
                            onClick={() => {
                                handleAdminOpenList();
                            }}
                        >
                            Create Request Owner
                        </Button>
                    }
                </div>
            }
            {prDetails &&
                (prDetails.status === PermanentReplacementStatus.PENDING || prDetails.status === PermanentReplacementStatus.INITIATED || prDetails.status === PermanentReplacementStatus.FAILED) &&
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        margin: '12px'
                    }}
                >
                    <Button
                        variant={'contained'}
                        size="small"
                        onClick={() => {
                            setOpenPrSelectDateSlotDialog(true);
                        }}
                        style={{
                            marginRight: '12px'
                        }}
                    >
                        Create Trial for Permanent Replacement
                    </Button>
                </div>
            }
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '12px',
                    paddingTop: '0px',
                    justifyContent: 'space-between',
                    gap: '5px',
                    flexWrap: 'wrap'
                }}
            >
                <div>
                    <p><b>Id:</b> {prDetails?.id}</p>
                </div>
                <div>
                    <p><b>Customer Name:</b> {prDetails?.customerName}</p>
                </div>
                <div>
                    <p><b>Customer Phone:</b> {prDetails?.customerPhone}</p>
                </div>
                <div>
                    <p>
                        <b>User Bundle Mapping Id:</b>
                        <Link
                            href={`/#/UserBundles/${prDetails?.userBundleMappingId}/Show`}>{prDetails?.userBundleMappingId}</Link>
                    </p>
                </div>
                <div>
                    <p><b>Owner Name:</b> {prDetails?.ownerName}</p>
                </div>
                <div>
                    <p><b>Owner Id:</b> {prDetails?.ownerId}</p>
                </div>
                <div>
                    <p><b>Created by:</b>
                        <Link
                            href={`/#/${prDetails?.createdByType === 'ADMIN' ? 'AdminUsers' : 'Customers'}/${prDetails?.createdBy}/Show`}>{prDetails?.createdBy}</Link>
                    </p>
                </div>
                <div>
                    <p><b>Created by Type: </b>
                        {prDetails?.createdByType}
                    </p>
                </div>

                <div>
                    <p><b>Owner Email:</b> {prDetails?.ownerEmail}</p>
                </div>
                <div>
                    <p><b>Created at: </b> {moment(prDetails?.createdAt).format('DD MMM YYYY HH:mm A')}</p>
                </div>
                <div>
                    <p><b>Status: </b> {prDetails?.status}</p>
                </div>
                <div>
                    <p><b>PR Trial Status:</b> {prDetails?.recentTrialStatus}</p>
                </div>
                <div>
                    <p><b>Option
                        Opted: </b> {prDetails?.customerOptedFor ? prDetails?.customerOptedFor : null}
                    </p>
                </div>
                <div>
                    <p><b>Updated at: </b> {moment(prDetails?.updatedAt).format('DD MMM YYYY HH:mm A')}</p>
                </div>
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                    {prDetails && prDetails?.prInitiationTags && Object?.entries(prDetails?.prInitiationTags)?.map(([reason, subReasons], index: number) => (
                        <div key={reason} style={{
                            display: 'flex',
                            gap: '30px',
                            width: '100%',
                        }}>
                            <p><b>Reason {index + 1}: </b>{reason}</p>
                            {Array?.isArray(subReasons) && subReasons?.map((subReason: string, ind: number) => (
                                <p><b>Sub Reason {ind + 1}: </b>{subReason}</p>
                            ))}
                        </div>
                    ))}
                </div>
                <div>
                    <p><b>PR Comments: </b>{prDetails?.comments}</p>
                </div>
                <div>
                    <p><b>Wants Old Cook Back:</b> {prDetails?.wantsOldCookBack !== null ? (prDetails?.wantsOldCookBack ? "Yes" : "No") : null}</p>
                </div>
                <div>
                    <p><b>Cook Informed: </b>{userBundleMapping?.cookSubscription?.[userBundleMapping?.cookSubscription?.length - 1]?.endDate ? "Yes" : "No"}</p>
                </div>
            </div>
            {
                (
                    !!trialsList &&
                    Array.isArray(trialsList) &&
                    trialsList.length > 0
                ) ?
                    <TrialsListForModule
                        data={trialsList}
                    />
                    :
                    <EmptyListPlaceholder
                        message='No Trials Found'
                    />
            }
            {openPrSelectDateSlotDialog && (
                <PRSelectDateSlotDialog
                    open={openPrSelectDateSlotDialog}
                    onClose={() => setOpenPrSelectDateSlotDialog(false)}
                    bundleDetails={userBundleMapping ? userBundleMapping : undefined}
                    subscriptionDetails={subscriptionDetails}
                    cookSubscriptionDetails={cookSubscriptionDetails}
                    permanentReplacementDetails={prDetails}
                    handleContinueClicked={handleDateSlotContinueClicked}
                    gracePeriodDetails={gracePeriodDetails}
                />
            )}
            {openPrTrialCreatedSuccessDialog && (
                <PRTrialCreateSuccessDialog
                    open={openPrTrialCreatedSuccessDialog}
                    onClose={() => {
                        refresh();
                        setOpenPrTrialCreatedSuccessDialog(false);
                    }}
                />
            )}
            {openCancelPRDialog && (
                <CancelPRRequestDialog
                    open={openCancelPRDialog}
                    onClose={() => setOpenCancelPRDialog(false)}
                    reasonSubReasonData={prReasonSubreasonData}
                    bundleDetails={userBundleMapping ? userBundleMapping : {} as UserBundlesResponse}
                    subscriptionDetails={subscriptionDetails}
                    refundDetail={refundDetail}
                    handleContinueClicked={handleCancelPRContinueClicked}
                />
            )}
        </Card>
    );
};

export default PermanentReplacementShow;
