import { GetListResult, GetOneResult } from "react-admin";
import HttpClient from "../HttpClient";
import { CookLocalityMapping } from "../interfaces/Cook/CookLocalityMapping";
import { CookResponse } from "../interfaces/Cook/CookResponse";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";
import { MgCookResponse } from "../interfaces/Cook/MgCookResponse";
import LocalityTypeInterface from "../interfaces/Locality/LocalityType";
import axios, { AxiosRequestConfig } from "axios";
import FreshLeadInterface from "../interfaces/Cook/FreshLeadInterface";
import { UpdateStatusCookProfileDto } from "../dtos/UpdateStatusCookProfileDto";
import { CookEscalationInterface } from "../interfaces/Cook/CookEscalationInterface";
import CookAuditInterface from "../interfaces/Cook/CookAuditInterface";
import CookSubscriptionInterface from "../interfaces/Cook/CookSubscriptionInterface";
import CheckInCheckoutInterface from "../interfaces/Cook/CookCheckinCheckoutInterface";
import { CookServiceTypeEnum } from "../enums/CookServiceTypeEnum";
import { CookVerificationStatus } from "../enums/CookVerificationStatus";
import { CookVerificationReportSeverity } from "../enums/CookVerificationReportSeverity";
import Op from "../interfaces/SequalizeFilters/SequalizeTypes";
import moment from "moment/moment";

export interface GetCookWalletResponse {
  id: number;
  cookId: number;
  amount: string;
  createdAt: string;
  updatedAt: string;
}
export interface GetCookWalletTransactionsResponse {
  count: number;
  next: number;
  data: {
    id: number;
    cookWalletId: number;
    amount: string;
    notes: string;
    tag: string;
    referenceId: number;
    referenceModelName: string;
    vendorPayoutEvent: {
      id: number;
      product: string;
      type: string;
      event: string;
      forMgCook: boolean;
      reason: string;
      amount: string;
      tags: string[];
      createdAt: string;
      updatedAt: string;
    }
    createdAt: string;
    updatedAt: string;
  }[];
}
interface UpdateKitDistributionPayload {
  inventories: {
    inventoryName: string;
    inventoryCount: number;
  }[];
}

export class CookService {
  processMgFilter(filters: any) {
    let isMgCook;
    if (filters.isMgCook) {
      if(filters.isMgCook === 'MG_COOK'){
        isMgCook = true;
      } else {
        isMgCook = false;
      }
    }
    return {isMgCook};
  }

  processOwnerFilter(filters:any){
    let ownerEmail;
    if(filters?.ownerEmail===undefined){
      return {}
    }
    if(filters?.ownerEmail === 'none'){
      ownerEmail={
        [Op.eq.toString()]: null
      }
    }
    else {
      ownerEmail={
        [Op.eq.toString()]: filters?.ownerEmail?.toString()?.trim()
      }
    }
    return {ownerEmail};
  }


  processDateRangeFilter(filters: any) {
    let createdAt;
    if (filters.startDates) {
      createdAt = {
        [Op.gte.toString()]: moment(filters.startDates)
      }
    }
    if (filters.endDates) {
      if (createdAt) {
        createdAt = {
          ...createdAt,
          [Op.lte.toString()]: moment(filters.endDates).add(1, 'd')
        }
      } else {
        createdAt = {
          [Op.lte.toString()]: moment(filters.endDates).add(1, 'd')
        }
      }
    }
    return {createdAt};
  }

  // Old API for cooks
  // async getAllCooks(
  //   limit: number,
  //   offset: number,
  //   filter: any
  // ): Promise<GetListResult<CookResponse>> {
  //   let filters: any = {};
  //   filters = ConstructSequelizeFilters(
  //     filter,
  //     ["firstName", "lastName", "mobile", "ownerEmail", "chefHub"],
  //     [
  //       "cookId",
  //       "workingCity",
  //       "gender",
  //       "cookCategory",
  //       "onboardingStatus",
  //       "onboardingSubStatus",
  //       "cookType",
  //       "workStatus",
  //     ]
  //   );
  //   let customFilters = this.processMgFilter(filter);
  //
  //   let data = await HttpClient.get("/centaurus/user/cook", {
  //     params: {
  //       filters: {
  //         ...filters,
  //         ...customFilters,
  //       },
  //       limit,
  //       offset,
  //     },
  //   });
  //   return {
  //     data: data?.data?.data,
  //     total: data?.data?.count,
  //   };
  // }
  // New API for cooks

  private additionalDetailFilters: { key: string, value: string }[] = [];
  private isFilterExists(newFilter: { key: string, value: string }): boolean {
    return this.additionalDetailFilters.some(filter =>
        filter.key === newFilter.key && filter.value === newFilter.value
    );
  }
  async getAllCooks(
    limit: number,
    offset: number,
    filter: any,
  ): Promise<GetListResult<CookResponse>> {
    if (filter && filter?.key && filter?.value) {
      const newFilter = { key: filter.key, value: filter.value };
      if (!this.isFilterExists(newFilter)) {
        this.additionalDetailFilters.push(newFilter);
      }
      delete filter?.key;
      delete filter?.value;
    }
    let filters: any = {};
      filters = ConstructSequelizeFilters(
        filter,
        ["firstName", "lastName", "mobile",'subSource'],
        ["cookId", "gender", "cookType", "category",'chefHub'],
        [],
        [],
        ['onboardingStatus', 'onboardingSubStatus', 'workStatus', 'cookCategory', 'source','preferredCity'],
      );
    let customFilters = this.processMgFilter(filter);
    let dateRangeFilter = this.processDateRangeFilter(filter);
    let ownerFilter = this.processOwnerFilter(filter);

    let data = await HttpClient.get("/centaurus/cook/all/details", {
      params: {
        filters: {
          ...filters,
          ...customFilters,
          ...dateRangeFilter,
          ...ownerFilter,
        },
        ...(filter?.additionalDetailFilters && { additionalDetailFilters: filter.additionalDetailFilters}),
        // additionalDetailFilters: {
        //   ...(additionalDetailFilters.key && { key: additionalDetailFilters.key }),
        //   ...(additionalDetailFilters.value && { value: additionalDetailFilters.value }),
        // },
        limit,
        offset,
      },
    });
    return {
      data: data?.data?.data,
      total: data?.data?.count,
    };
  }

  async getAllStarChefCooks(
    limit: number,
    offset: number,
    filter: any
  ): Promise<GetListResult<CookResponse>> {
    let filters: any = {};
    filter.cooktype = "StarChef (Full-time)";
    filters = ConstructSequelizeFilters(
      filter,
      ["fname", "mobile", "zohoCookId"],
      ["workingCity", "gender", "category", "cooktype"]
    );
    let data = await HttpClient.get("/centaurus/user/cook", {
      params: {
        filters,
        limit,
        offset,
      },
    });
    return {
      data: data?.data?.data,
      total: data?.data?.count,
    };
  }

  async checkCookAvailability(
    cookId: number,
    trialId: number
  ): Promise<GetOneResult<CookResponse>> {
    let res = await HttpClient.post(
      "/centaurus/v3/trials/checkCookAvailability",
      {
        cookId,
        trialId,
      }
    );
    return res?.data;
  }

  async getOneCook(cookId: number): Promise<GetOneResult<CookResponse>> {
    let res = await HttpClient.get(`/centaurus/user/cook/${cookId}`);
    return res;
  }

  async getManyCooks(ids: number[]): Promise<GetListResult<CookResponse>> {
    const data = [];

    for (const id of ids) {
      const res = await HttpClient.get(`/centaurus/user/cook/${id}`);
      data.push(res.data);
    }

    return {
      data: data,
      total: data.length,
    };
  }

  async getAllCookSubscriptionForCook(
    cookId: number,
    limit: number,
    offset: number,
    sort: {}
  ) {
    const data = await HttpClient.get(
      `centaurus/cook-subscription/cooks/${cookId}`,
      {
        params: {
          limit,
          offset,
          sort,
        },
      }
    );

    return {
      data: data.data.data,
      total: data.data.count,
    };
  }

  async getSubscriptionForACook(
    subsciptionId: number
  ): Promise<CookSubscriptionInterface> {
    const data = await HttpClient.get(
      `centaurus/cook-subscription/${subsciptionId}`
    );

    return data.data;
  }

  async getAllCheckInCheckOutForCook(
    subsciptionId: number,
    payload: { houseId: number }
  ): Promise<CheckInCheckoutInterface[]> {
    const data = await HttpClient.get(
      `centaurus/cook-subscription/${subsciptionId}/checkins`,
      {
        params: payload,
      }
    );

    return data.data;
  }

  async updateMobileNumber(
    cookId: number,
    payload: any
  ): Promise<CookResponse> {
    const data = await HttpClient.patch(
      `/centaurus/user/cook/${cookId}/phone-number`,
      payload
    );
    return data?.data;
  }

  async addLocalityMapping(
    cookId: number,
    payload: LocalityTypeInterface
  ): Promise<CookLocalityMapping> {
    const data = await HttpClient.post(
      `/centaurus/cook-locality-mapping/cook/${cookId}`,
      payload
    );
    return data?.data;
  }

  async getLocalityMapping(
    cookId: number,
    limit: number = 10,
    offset: number = 0
  ) {
    const data = await HttpClient.get(
      `/centaurus/cook-locality-mapping/cook/${cookId}`,
      {
        params: {
          limit,
          offset,
        },
      }
    );
    return data;
  }
  async getQrChefpayTransactions(cookId:number){
    const data=await HttpClient.get(`centaurus/user-cook-payment/cook/${cookId}/all-payment`)

    return data;
  }

  async getCookWallet(cookId: number): Promise<GetCookWalletResponse> {
    const data = await HttpClient.get(`/centaurus/cook-wallet/cooks/${cookId}`);
    return data.data;
  }
  async getCookWalletTransactions(
    cookId: number,
    limit: number,
    offset: number,
    sort: {}
  ): Promise<GetCookWalletTransactionsResponse> {
    const data = await HttpClient.get(
      `/centaurus/cook-wallet/cooks/${cookId}/transactions`,
      {
        params: {
          limit,
          offset,
          sort,
        },
      }
    );

    return data.data;
  }
  async redeemCookWalletBalance(cookId: number, amount: string) {
    const response = await HttpClient.post(
      `/centaurus/cook-wallet/${cookId}/redeem`,
      { amount }
    );
    return response;
  }

  async getMgCookSalary(cookId: number): Promise<MgCookResponse[]> {
    const data = await HttpClient.get(`/centaurus/mg-salary/${cookId}`);
    return data?.data;
  }

  async createMgCookSalaryComment(entityId: number, payload: {comment: string}): Promise<MgCookResponse> {
    const data = await HttpClient.patch(`/centaurus/mg-salary/${entityId}/add-comment`, payload);
    return data?.data;
  }

  async createMgCook(payload: {
    cookId?: number;
    salary: number;
    startDate: string;
    endDate?: string;
  }): Promise<MgCookResponse> {
    const data = await HttpClient.post("/centaurus/mg-salary", payload);
    return data?.data;
  }

  async editMgCook(
    id: string,
    payload: { salary: number; startDate: string; endDate?: string }
  ): Promise<MgCookResponse> {
    const data = await HttpClient.patch(
      `/centaurus/mg-salary/${id}/update`,
      payload
    );
    return data?.data;
  }

  async convertMgCookToPartTime(
    cookId: number,
    payload: { startDate: string }
  ): Promise<MgCookResponse> {
    const data = await HttpClient.post(
      `/centaurus/mg-salary/${cookId}/convert-part-time`,
      payload
    );
    return data?.data;
  }

  async getTRForCooks(id: number, limit: number, offset: number) {
    const data = HttpClient.get(`/centaurus/temp-replacement/cook/${id}/all`, {
      params: {
        limit,
        offset,
      },
    });
    return data;
  }

  async requestedForWorkCook(cookId: number) {
    const data = HttpClient.post(
      `centaurus/user/cook/${cookId}/request-work`,
      {}
    );
    return data;
  }

  async getCookRating(id: number) {
    const data = HttpClient.get(`/internal-rating/cook/${id}`);
    return data;
  }

  async getWeightageTable() {
    const data = HttpClient.get(`/internal-rating/weightage-table`);
    return data;
  }

  async deleteLocality(cookId: number, localityId: number) {
    const data = HttpClient.delete(
      `/centaurus/cook-locality-mapping/cook/${cookId}/locality/${localityId}`
    );
    return data;
  }

  async createCook(payload: { mobile: string }): Promise<CookResponse> {
    const data = await HttpClient.post("/centaurus/user/cook", payload);
    return data?.data;
  }

  async getCookByLead(
    cookId: number
  ): Promise<GetOneResult<FreshLeadInterface>> {
    const data = await HttpClient.get(`/centaurus/cook/${cookId}/cook-profile`);
    return data;
  }

  async addCookProfile(cookId: number, payload: any): Promise<CookResponse> {
    const data = await HttpClient.post(
      `/centaurus/cook/${cookId}/cook-profile`,
      payload
    );
    return data?.data;
  }

  async editCookProfile(cookId: number, payload: any): Promise<CookResponse> {
    const data = await HttpClient.patch(
      `/centaurus/cook/${cookId}/cook-profile`,
      payload
    );
    return data?.data;
  }

  async updateCookTier(cookId: number, payload: any): Promise<CookResponse> {
    const data = await HttpClient.patch(
      `/centaurus/cook/${cookId}/cook-profile/tier`,
      payload
    );
    return data?.data;
  }

  async editCookProfileStatus(
    cookId: number,
    payload: UpdateStatusCookProfileDto
  ): Promise<any> {
    const data = await HttpClient.patch(
      `/centaurus/cook/${cookId}/cook-profile/status`,
      payload
    );
    return data?.data;
  }

  async addCommment(
    cookId: number,
    payload: { comment: string }
  ): Promise<any> {
    const data = await HttpClient.patch(
      `/centaurus/cook/${cookId}/cook-profile/comments`,
      payload
    );
    return data?.data;
  }

  async updateOwner(
    cookId: number,
    payload: { authUserId: number }
  ): Promise<any> {
    const data = await HttpClient.patch(
      `/centaurus/cook/${cookId}/cook-profile/owner`,
      payload
    );
    return data?.data;
  }

  async updateHub(cookId: number, payload: { hubId: any }): Promise<any> {
    const data = await HttpClient.patch(
      `/centaurus/cook/${cookId}/cook-profile/hub`,
      payload
    );
    return data;
  }
  async markAssessmentComplete(cookId: number) {
    const data = await HttpClient.patch(
      `/centaurus/cook/${cookId}/cook-profile/assessment-complete`
    );
    return data;
  }
  async markTrainingComplete(cookId: number) {
    const data = await HttpClient.patch(
      `/centaurus/cook/${cookId}/cook-profile/training-complete`
    );
    return data;
  }
  async markAuthbridgeVerification(cookId: number) {
    const data = await HttpClient.patch(
      `/centaurus/cook/${cookId}/cook-profile/initiate-verification`
    );
    return data;
  }
  async markApprovalVerification(cookId: number) {
    const data = await HttpClient.patch(
      `/centaurus/cook/${cookId}/cook-profile/approval-done`
    );
    return data;
  }
  async updateKitDistribution(
    cookId: number,
    payload: UpdateKitDistributionPayload
  ) {
    const data = await HttpClient.patch(
      `/centaurus/cook/${cookId}/cook-profile/kit-distribution`,
      payload
    );
    return data;
  }

  async scheduleSkillAssessment(
    cookId: number,
    payload: { time: Date }
  ): Promise<any> {
    const data = await HttpClient.patch(
      `/centaurus/cook/${cookId}/cook-profile/skill-assessment`,
      payload
    );
    return data?.data;
  }

  async getAWSLinks(directory: string, imageName: string) {
    let payload = {
      directory: "cook-profile",
      fileName: imageName,
    };
    const data = await HttpClient.get("uploadImage/getSignedUrl", {
      params: payload,
    });
    return data?.data;
  }

  async uploadImageToAWS(url: string, binaryImage: any) {
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const data = await axios.put(url, binaryImage, config);
    return data;
  }

  async updateRatingForSkills(cookId: number, payload: any) {
    const data = await HttpClient.patch(
      `/centaurus/cook/${cookId}/cook-profile/rate-assessment`,
      payload
    );
    return data?.data;
  }
  async getTopicProgress(cookId: number, query: any = {}) {
    const data = await HttpClient.get(
      `/centaurus/assessment/cook/${cookId}/topic-progress`,
        {
          params: {
            ...query,
          }
        }
    );
    return data?.data;
  }
  async getQuestionOfTopicProgressId(topicProgressId: number, query: any = {}) {
    const data = await HttpClient.get(
      `/centaurus/assessment/topic-progress/${topicProgressId}/questions`,
        {
          params: {
            ...query,
          }
        }
    );
    return data?.data;
  }

  async updateAssessment(payload: any = {}) {
    const data = await HttpClient.patch(
      `/centaurus/assessment/bulk-update-progress`,
        {
          ...payload
        }
    );
    return data?.data;
  }

  async updateSoftSkillRating(cookId: number, payload: any) {
    const data = await HttpClient.patch(
      `/centaurus/cook/${cookId}/cook-profile/rate-soft-skills`,
      payload
    );
    return data?.data;
  }

  async updateTrainingRatings(cookId: number, payload: any) {
    const data = await HttpClient.patch(
      `/centaurus/cook/${cookId}/cook-profile/rate-training`,
      payload
    );
    return data?.data;
  }

  async markOnboardingComplete(cookId: number) {
    const data = await HttpClient.patch(
      `/centaurus/cook/${cookId}/cook-profile/mark-onboarded`
    );
    return data?.data;
  }

  async markFreeToWork(cookId: number, paylaod: any) {
    const data = await HttpClient.patch(
      `/centaurus/cook/${cookId}/cook-profile/mark-free`, paylaod,
    );
    return data?.data;
  }
  async addComment(cookId: number, escalationId: number, payload: any) {
    const data = await HttpClient.patch(
      `/centaurus/cook/${cookId}/cook-profile/escalation/${escalationId}/comment`, payload,
    );
    return data?.data;
  }

  async createEscalation(cookId: number, payload: any) {
    const data = await HttpClient.post(
      `/centaurus/cook/${cookId}/cook-profile/escalation`,
      payload
    );
    return data?.data;
  }

  async getEscalations(cookId: number, limit: number, offset: number, filters: any): Promise<any> {
    const data = await HttpClient.get(
      `/centaurus/cook/${cookId}/cook-profile/v2/escalation`,
        {
            params: {
                limit: limit,
                offset: offset,
                filters: filters,
                sort: {"field":"id","order":"DESC"},
            },
        },
    );
    return data?.data;
  }

  async updateEscalationsStatus(
      cookId: number,
      escalationData: {escalationId: number, actionByCook: string},
  ): Promise<CookEscalationInterface[]> {
    const data = await HttpClient.patch(
        `/centaurus/cook/${cookId}/cook-profile/mark-escalation-by-cook`, {
          ...escalationData,
        }
    );
    return data?.data;
  }

  async getOneMealsForCook(
    cookId: number,
    limit: number,
    offset: number,
    sort: {}
  ) {
    let filter = { cookId: cookId };
    const filters = ConstructSequelizeFilters(filter, [], ["cookId"]);
    let data: any = await HttpClient.get(`/centaurus/one-meal-service`, {
      params: {
        limit,
        offset,
        filters,
        sort,
      },
    });

    return data?.data;
  }

  async changeCookType(cookId: number, payload: { cookType: string }) {
    const data = await HttpClient.patch(
      `/centaurus/cook/${cookId}/cook-profile/cook-type`,
      payload
    );
    return data?.data;
  }

  async markNotFreeForWork(cookId: number) {
    const data = await HttpClient.patch(
      `/centaurus/cook/${cookId}/cook-profile/mark-not-free`
    );
    return data?.data;
  }

  async getAuditLogs(cookId: number): Promise<CookAuditInterface[]> {
    const data = await HttpClient.get(
      `/centaurus/cook/${cookId}/cook-profile/audit-logs`
    );
    return data?.data;
  }

  async overrideAuthBridge(cookId: number) {
    const data = await HttpClient.patch(
      `/centaurus/cook/${cookId}/cook-profile/mark-clear`
    );
    return data?.data;
  }

  async markCookLeave(payload: any) {
    const data = await HttpClient.post(`/centauras/cook-slotting/mark-leave-daywise`, payload);
    return data?.data;
  }

  async getCookSlots(query: any) {
    const data = await HttpClient.get(`/common/slotting/get-daywise-slot`, {
          params: {
            ...query,
          }
        }
    );
    return data?.data;
  }

  async changeSlotStatus(payload: any) {
    const data = await HttpClient.post(`/centauras/cook-slotting/set-slot-status`, payload);
    return data?.data;
  }

  async getAllDailyCooks(
      limit: number,
      offset: number,
      filter: any
  ): Promise<GetListResult<CookResponse>> {
    let data = await HttpClient.get("/centaurus/houses/get-mg-cook-serve", {
      params: {
        limit,
        offset,
        ...filter,
      },
    });
    return {
      data: data?.data?.data,
      total: data?.data?.count,
    };
  }
  async getAllDailyCooksSlc(
      limit: number,
      offset: number,
      filter: any
  ): Promise<GetListResult<CookResponse>> {
    let filters: any = {};
    filters = ConstructSequelizeFilters(
        filter,
        [],
        ["cookId", "serviceId"],
    );

    let data = await HttpClient.get("/centaurus/houses/get-cook-slc-mapping", {
      params: {
        filters,
        limit,
        offset,
      },
    });
    return {
      data: data?.data?.data,
      total: data?.data?.count,
    };
  }

  async getAllCooksAndSlotsForCalender(payload: any) {
    const data = await HttpClient.get(`/centaurus/one-meal-service/get-oms-calender`, {
      params: {
        ...payload,
      }
    });
    return data?.data;
  }

  async createCookReferral(payload: any) {
    const data = await HttpClient.post(`/centaurus/v3/cook-referral`, payload);
    return data?.data;
  }

  async getCookReferrals(
      limit: number,
      offset: number,
      sort: {},
      filters: {},
  ) {
    const data = await HttpClient.get(
        `/centaurus/v3/cook-referral/all`,
        {
          params: {
            limit,
            offset,
            sort,
            filters,
          },
        }
    );

    return {
      data: data.data.data,
      total: data.data.count,
    };
  }


  async getCookActivityCoordinates(cookId: number, fromDate: string, toDate: string) {
    const data = await HttpClient.get(`/centaurus/cook/${cookId}/cook-profile/get-cook-coordinates`, {
      params: {
        fromDate,
        toDate,
      }
    });
    return data?.data;
  }

  async getCookActivity(cookId: number, query: any = {}) {
    const data = await HttpClient.get(`/centaurus/cook/${cookId}/cook-profile/get-cook-activity`, {
      params: {
        ...query,
      }
    });
    return data?.data;
  }

  async sendWhatsApp(payload: any = {}) {
    const data = await HttpClient.post(`/centaurus/user/cook/send-whatsapp-tracker`, {
      ...payload,
    });
    return data?.data;
  }

  async getAllcookServeCalendar (payload: any) {
    const data = await HttpClient.get(`/centauras/cook-slotting/get-cook-serve-calendar`, {
      params: {
        ...payload,
      }
    });
    return data?.data;
  }

  async addCookType(cookId: number, payload: { cookTypeList: CookServiceTypeEnum[] }) {
    const data = await HttpClient.patch(
      `/centaurus/cook/${cookId}/cook-profile/add-cook-type`,
      payload
    );
    return data?.data;
  }

  async changeCookVerificationStatusAndReportSeverity(cookId: number,paylaod:{verificationStatus:CookVerificationStatus,verificationReportSeverity:CookVerificationReportSeverity}){
    const data=await HttpClient.patch(
      `/centaurus/cook/${cookId}/cook-profile/change-verification-status`,
      paylaod
    );
    return data?.data;

  }

  async removeCookType(cookId: number, payload: { cookType: CookServiceTypeEnum }) {
    const data = await HttpClient.patch(
      `/centaurus/cook/${cookId}/cook-profile/remove-cook-type`,
      payload
    );
    return data?.data;
  }

  async recalculateV1(cookId: number) {
    const data = await HttpClient.post(
      `centauras/cook-slotting/recalculate/v1/cooks/${cookId}`
    );
    return data;
  }

  async recalculateV2(cookId: number) {
    const data = await HttpClient.post(
      `/centauras/cook-slotting/recalculate/v2/cooks/${cookId}`
    );
    return data;
  }

  async getCookLocation(query: any) {
    const data = await HttpClient.get(
      `/centaurus/user/cook/location`,
        {
          params: {
            ...query,
          }
        },
    );
    return data?.data;
  }

  async patchCookProfileSlug(cookId: number) {
    const data = await HttpClient.patch(`/centaurus/cook/${cookId}/cook-profile/slug`);
    return data;
  }

  async getPartTimeCookCalender(query: any) {
    const data = await HttpClient.get('/common/slotting/get-daywise-slot-date-range',
      {
        params: {
          ...query,
        }
      }
    );
    return data?.data;
  }

  async patchCookResolution(cookId: number, escalationId: number, payload: any): Promise<any> {
    const data = await HttpClient.patch(`/centaurus/cook/${cookId}/cook-profile/escalation/${escalationId}/resolution`, payload);
    return data?.data;
  }

  async getAllRecommendedDish(cookId: number): Promise<any> {
    const data = await HttpClient.get(`/centaurus/cook/${cookId}/cook-profile/recommended-dish`);
    return data?.data;
  }

  async addRecommendedDish(cookId: number, dishId: number): Promise<any> {
    const data = await HttpClient.post(`/centaurus/cook/${cookId}/cook-profile/recommended-dish/${dishId}`);
    return data?.data;
  }

  async removeRecommendedDish(cookId: number, dishId: number): Promise<any> {
    const data = await HttpClient.delete(`/centaurus/cook/${cookId}/cook-profile/recommended-dish/${dishId}`);
    return data?.data;
  }

  async getAllCuisineCategories(): Promise<{id: number; name: string}[]> {
    const data = await HttpClient.get(`/cuisine/categories`);
    return data?.data;
  }

  async getAllCuisineCategoryDishes(categoryId: number): Promise<{id: number; name: string}[]> {
    const data = await HttpClient.get(`/cuisine/categories/${categoryId}/dishes`);
    return data?.data;
  }

  async getCookServeSlots(date:string,cookId:number,totalPeople:number){
    const data = await HttpClient.get(`/common/slotting/get-daywise-slot?date=${date}&cookId=${cookId}&totalPeople=${totalPeople}`);
    return data?.data; 
  }
  async preCheckIn(cookId:number,houseId:number){
    const data= await HttpClient.post(`/centaurus/v3/checkins/cook/${cookId}/houses/${houseId}/pre-checkin`)
    return data;
  }
}