import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    TextField
} from "@mui/material";
import FieldViewService from "../../services/FieldViewService";
import FieldViewResponse from "../../interfaces/FieldView/FieldViewResponse";
import CloseIcon from '@mui/icons-material/Close';
import {useListContext} from "react-admin";
import GetFilterComponent from '../Common/GetFilterComponent';

interface Props {
    setOpenAdvancedFilter: any;
    openAdvancedFilter: boolean;
    filter:any;
    setFilter:any;
}

const FieldService = new FieldViewService();

const AdvancedCookFilterDialog = (props: Props) => {

    const {setFilters, filterValues} = useListContext();

    const [fields, setFields] = useState<FieldViewResponse[]>([]);

    const {filter, setFilter} = props;
    const [field, setField] = useState<any>(null);
    const [updatedFields,setUpdatedFields]=useState<FieldViewResponse[]>([]);
    
    
    const resetFilter = () => {
        setFilters({}, [], false);
        setFilter([]);
    };

    useEffect(() => {
        if (filterValues?.additionalDetailFilters) {
            let additionalFilterValues=JSON.parse(filterValues?.additionalDetailFilters);
            let additionalDetailFilters = additionalFilterValues.map((value:any)=>{
                let valueDetail = fields.find((each:any)=>each?.label===value?.key);
                return {...value,values:valueDetail?.values,type:valueDetail?.inputType}
            })
            setFilter(additionalDetailFilters);
        }
    }, [filterValues?.additionalDetailFilters,fields,setFilter]);

    useEffect(() => {
        FieldService.getAllDetailParameters(100, 0).then((res) => {
            setFields(res?.data);
        })
    }, []);
    useEffect(() => {
        // Filter out the fields that are already selected in the filter
        const selectedFilterKeys = filter.map((eachFilter: any) => eachFilter.key); // Get keys of selected filters
        const availableFields = fields.filter((field: FieldViewResponse) => !selectedFilterKeys.includes(field.label)); // Exclude selected filters
        setUpdatedFields(availableFields); // Update the state with remaining fields
    }, [filter, fields]);

    return(
        <Dialog open={props?.openAdvancedFilter} onClose={() => {props?.setOpenAdvancedFilter(false)}}>
            <DialogTitle>
                Advanced filter Dialog
            </DialogTitle>
            <DialogContent>
                {/*add the filter here*/}
                <Box display={'flex'} justifyContent={'flex-start'} alignItems={"center"}>
                    <TextField
                        select
                        label="Select Label"
                        fullWidth
                        sx={{width: 300,mt:2}}
                        onChange={(e) => {
                            setField(e.target.value)
                        }}
                        value={field}
                    >
                        {/* Add menu items dynamically based on labels */}
                        {updatedFields.map((eachField:any) => (
                            <MenuItem key={eachField?.id} value={eachField}>
                                {eachField?.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Button variant={'outlined'} sx={{mt: 2, ml: 2}} disabled={!field} onClick={() => {
                        setFilter((prevState:any) => [...prevState,{key:field.label,value:"",values:field.values,type:field.inputType}])
                        setField(null);
                    }}>
                        {'Add Filter'}
                    </Button>
                    <Button color={'error'} variant={'outlined'} sx={{mt: 2, ml: 2}} onClick={resetFilter}>
                        {'Clear Filter'}
                    </Button>
                </Box>
                {filter.length>0?filter?.map((eachFilter:any, index:number) => {
                    return(
                        <>
                            <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} key={eachFilter?.key}>
                                <Box>{(index+1)+". "+eachFilter?.key}</Box>
                                <IconButton
                                    sx={{ml: 2}}
                                    onClick={() => {
                                        filter.splice(index, 1);
                                        setFilter([...filter]);
                                    }}
                                    color={'error'}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <GetFilterComponent type={eachFilter?.type} values={eachFilter?.values} value={eachFilter.value} setFilter={setFilter} index={index} filter={filter}/>
                        </>
                    )
                }):"Add Values to Filter"}
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} color={'error'} onClick={() => {props?.setOpenAdvancedFilter(false)}}>
                    {'Close'}
                </Button>
                <Button variant={'contained'} color={'primary'} onClick={() => {
                    setFilters({additionalDetailFilters: JSON.stringify(filter.map((value:any)=>{
                        return {key:value.key,value:value?.type==="DATE"?`Symbol('like'): %${value?.value?.toString()?.trim()}%`:value?.value};
                    }))}, [], false);
                    props?.setOpenAdvancedFilter(false);
                }}>
                    {'Filter'}
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default AdvancedCookFilterDialog;
