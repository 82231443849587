import { FormControl, FormControlLabel,Radio, RadioGroup, TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

const GetFilterComponent = (props:any) =>{
    const{type,setFilter,index,values,filter}=props;

    const handleFilterChange = (e:any) => {
        const newFilter = [...filter];
        newFilter[index] = { ...newFilter[index], value:e };
        setFilter(newFilter);
    }

    // const handleCheck = (e:any, value: string) => {
    //     const newFilter = [...filter];
    //     let currentValue = [...newFilter[index].value]; // copy the current value array
    
    //     if (e.target.checked) {
    //         // If the checkbox is checked, add the value if it's not already in the array
    //         currentValue = [...currentValue, value];
    //     } else {
    //         // If the checkbox is unchecked, remove the value from the array
    //         currentValue = currentValue.filter((val) => val !== value);
    //     }
    
    //     newFilter[index] = { ...newFilter[index], value: currentValue }; // update the filter
    //     setFilter(newFilter); // set the updated filter
    // };
    
    
    return (
        <>
        
            {/* Filter component goes here */}
            {
                type==="RADIO"&&
                <FormControl>
                    <RadioGroup
                        name="controlled-radio-buttons-group"
                        value={props?.value}
                        onChange={(e,v)=>{
                            handleFilterChange(v)
                        }
                        }
                        row
                        >
                            {
                                JSON.parse(values).map((value:any)=>(
                                     <FormControlLabel value={value} control={<Radio />} label={value} />
                                ))
                            }   
                    </RadioGroup>
              </FormControl>
            }
            {
                type==="DATE"&&
                <DatePicker
                    label={""}
                    renderInput={(params: any) => <TextField variant={'outlined'} {...params} color="primary" />}
                    value={props?.value}
                    inputFormat="dd MMM yyyy"
                    onChange={(newValue) => {
                    handleFilterChange(moment(newValue).format('YYYY-MM-DD'))
                    }
                   }
                />

            }
            {/* {
                type==='CHECKBOX'&&
                <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                        {
                            JSON.parse(values).map(
                                (value:any) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox 
                                                checked={props?.value?.includes(value)} 
                                                onChange={(e) => handleCheck(e, value)} // pass the value here
                                            />
                                        }
                                        label={value}
                                    />
                                )
                            )
                        }
                    </FormGroup>
                    </FormControl>
            } */}
            {
                type==='TEXT'&&
                <TextField
                    sx={{ minWidth: 210 }}
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    onChange={(e)=>{
                        handleFilterChange(e.target.value)
                    }}
                    value={props?.value}
                    placeholder="Enter Here..."
              />
            }
        
        </>
    )

}

export default GetFilterComponent