import React, {useEffect, useState} from 'react';
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import PermanentReplacementService from "../../services/PermanentReplacementService";
import CircularLoader from "../Common/CircularLoader";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import PermanentReplacementListForModule from "../PermanentReplacement/PermanentReplacementListForModule";
import {Button} from "@mui/material";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";
import {GetListResult, useRecordContext} from 'react-admin';
import { UserBundleMappingStatus } from "../../enums/UserBundleMappingStatus";
import InitiatePRRequestDialog from "../PermanentReplacement/InitiatePRRequestDialog";
import UserBundleService from "../../services/BundleService";
import {CookSubscriptionResponse} from "../../interfaces/UserBundleMapping/CookSubscriptionForBundle";
import ConstructSequelizeFilters from "../../utils/ConstructSequelizeFilters";
import ReasonSubreasonServices from '../../services/ReasonSubreasonServices';
import {ReasonSubReasonResponse} from "../../interfaces/ReasonSubReason/ReasonSubReasonResponse";
import {SubscriptionResponse} from "../../interfaces/UserBundleMapping/SubscriptionForBundle";
import PRSelectDateSlotDialog from "../PermanentReplacement/PRSelectDateSlotDialog";
import {UserBundlesResponse} from "../../interfaces/UserBundleMapping/UserBundlesResponse";
import {PermanentReplacementResponse} from "../../interfaces/PermanentReplacement/PermanentReplacementResponse";
import moment from "moment";
import PRTrialCreateSuccessDialog from "../PermanentReplacement/PRTrialCreateSuccessDialog";

const permanentReplacementService = new PermanentReplacementService();
const userBundleService = new UserBundleService();
const reasonSubReasonService = new ReasonSubreasonServices();

const UserBundlePrTab = (props: {userBundleMappingId: number}) => {
    let [subData, setSubData] = useState<any>(null);
    let [isLoading, setIsLoading] = useState<boolean>();
    const [openInitiatePRDialog, setOpenInitiatePRDialog] = useState<boolean>(false);
    const [cookSubscriptionDetails, setCookSubscriptionDetails] = useState<CookSubscriptionResponse>();
    const [prReasonSubreasonData, setPrReasonSubreasonData] = useState<ReasonSubReasonResponse[]>([]);
    const [prData, setPrData] = useState<PermanentReplacementResponse>();
    const [openPrSelectDateSlotDialog, setOpenPrSelectDateSlotDialog] = useState<boolean>(false);
    const [subscriptionDetails, setSubscriptionDetails] = useState<SubscriptionResponse>();
    const [allSubscriptionDetails, setAllSubscriptionDetails] = useState<SubscriptionResponse[]>();
    const [gracePeriodDetails, setGracePeriodDetails] = useState<SubscriptionResponse>();
    const [openPrTrialCreatedSuccessDialog, setOpenPrTrialCreatedSuccessDialog] = useState<boolean>(false);
    const record = useRecordContext()
    const userBundleStatus = record.status;
    const handleInitiatePR = () => {
        if(moment().isAfter(moment(gracePeriodDetails?.endDate))) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: 'Subscription has already ended',
                type: CustomSnackbarTypes.ERROR
            });
            return;
        }
        else {
            setOpenInitiatePRDialog(true);
        }
    }
    const getCurrentCookSubscriptionDetails = (userBundleMappingId: number, limit: number, offset: number) => {
        setIsLoading(true);
        let filters = ConstructSequelizeFilters({userBundleMappingId: userBundleMappingId}, [], [], ['endDate'], []);
        userBundleService?.fetchAllCookSubscriptionsForUserBundleMapping(userBundleMappingId, limit, offset, {order: "DESC", field: "startDate"}, filters)
            .then((res: any) => {
                if(res?.data?.data?.length > 0) {
                    setCookSubscriptionDetails(res?.data?.data[0]);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            })
    }
    const getSubscriptionDetails = (userBundleMappingId: number, limit: number, offset: number) => {
        setIsLoading(true);
        userBundleService?.fetchAllSubscriptionsForUserBundleMapping(userBundleMappingId, limit, offset, {order: "ASC", field: "startDate"})
            .then((res: GetListResult<SubscriptionResponse>) => {
                setIsLoading(false);
                if(res?.data?.length > 0) {
                    setSubscriptionDetails(res?.data[0]);
                    setAllSubscriptionDetails(res?.data);
                    if(res?.data?.length > 1) {
                        setGracePeriodDetails(res?.data[res?.data.length-1]);
                    }
                }
            })
            .catch((err) => {
                setIsLoading(false);
            })
    }
    const getPRReasonSubReasonData = (module: string, actionType: string) => {
        setIsLoading(true);
        reasonSubReasonService.fetchReasonAndSubReason(module, actionType)
            .then((res: any) => {
                setIsLoading(false);
                if(res?.data && res?.data?.data?.length > 0) {
                    setPrReasonSubreasonData(res?.data?.data);
                }
            })
            .catch((err) => {
                setIsLoading(false);
            })
    }
    const handleInitiatePRContinueClicked = (reason: string, subReason: string, comment: string) => {
        if(reason && reason!=="Other"&&subReason && comment) {
            // call the api to initiate the PR request
            setIsLoading(true);
            permanentReplacementService?.initiatePRRequest(props?.userBundleMappingId, reason, subReason, comment).then((res) => {
                if(res) {
                    setIsLoading(false);
                    setPrData(res?.data);
                    setOpenInitiatePRDialog(false);
                    setOpenPrSelectDateSlotDialog(true);
                }
            }).catch((err) => {
                setIsLoading(false);
                setOpenInitiatePRDialog(false);
            })
        }
        else if(reason && reason==="Other"&& comment){
            setIsLoading(true);
            permanentReplacementService?.initiatePRRequest(props?.userBundleMappingId, reason, comment , comment).then((res) => {
                if(res) {
                    setIsLoading(false);
                    setPrData(res?.data);
                    setOpenInitiatePRDialog(false);
                    setOpenPrSelectDateSlotDialog(true);
                }
            }).catch((err) => {
                setIsLoading(false);
                setOpenInitiatePRDialog(false);
            })

        }
        else {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: 'Please fill all the fields',
                type: CustomSnackbarTypes.ERROR
            });
        }
    }
    const handleDateSlotContinueClicked = (selectedDate: string, selectedSlot: string, selectedOption: string) => {
        if(selectedDate && selectedSlot && prData) {
            setIsLoading(true);
            if(allSubscriptionDetails?.some((e: any) => e.status === "ACTIVE" && e.type !== "GRACE")) {
                if(!selectedOption) {
                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                        message: "Select a visit choice to proceed",
                        type: CustomSnackbarTypes.WARNING,
                    });
                    setIsLoading(false);
                    return;
                }
            }
            permanentReplacementService?.createPRTrial(prData?.id, selectedSlot, selectedDate, selectedOption, cookSubscriptionDetails?.id)
                .then((res) => {
                    setIsLoading(false);
                    if(res) {
                        setOpenPrSelectDateSlotDialog(false);
                        refreshListener();
                        setOpenPrTrialCreatedSuccessDialog(true);
                    }
                    console.log(res);
                }).catch((err) => {
                    setIsLoading(false);
                })
        }
    }
    const refreshData = (userBundleMappingId: number) => {
        setIsLoading(true)
        permanentReplacementService.getAllPermanentReplacementForUserBundle(userBundleMappingId)
            .then((data) => {
                setSubData(data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            })
        getCurrentCookSubscriptionDetails(userBundleMappingId, 100, 0);
        getSubscriptionDetails(userBundleMappingId, 100, 0)
    }

    useEffect(() => {
        if(cookSubscriptionDetails) {
            getPRReasonSubReasonData('PERMANENT_REPLACEMENT', 'PR_CREATION');
        } else {
            getPRReasonSubReasonData('PERMANENT_REPLACEMENT', 'PR_CREATION_WITHOUT_COOK_SUBSCRIPTION');
        }
    }, [cookSubscriptionDetails])

    const refreshListener = () => {
        refreshData(props.userBundleMappingId);
    }

    useEffect(() => {
        const listener = EventBus.getInstance();
        listener.on(EventBusEventsEnum.REFRESH_CHILD_USER_BUNDLE_MAPPING_TABS, refreshListener)
        return () => {
            listener.removeListener(EventBusEventsEnum.REFRESH_CHILD_USER_BUNDLE_MAPPING_TABS, refreshListener)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        refreshData(props.userBundleMappingId);
        // eslint-disable-next-line
    }, [props.userBundleMappingId])

    return (
        <div>
            {
                isLoading ?
                    <CircularLoader/>
                    :
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            {userBundleStatus === UserBundleMappingStatus.ACTIVE && (
                                <Button
                                variant={'contained'}
                                onClick={() => {
                                    handleInitiatePR();
                                    // EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
                                    //     message: "Are you sure you want to Initiate a request for Permanent Replacement?",
                                    //     onClose: (confirmed: boolean) => {
                                    //         if (confirmed) {
                                    //             permanentReplacementService.createPermanentReplacement(props.userBundleMappingId)
                                    //                 .then((res) => {
                                    //                     EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                    //                         message: 'Success',
                                    //                         type: CustomSnackbarTypes.SUCCESS
                                    //                     });
                                    //                     refreshData(props.userBundleMappingId);
                                    //                 })
                                    //         }
                                    //     },
                                    //     title: "Confirmation"
                                    // });
                                }}
                            >
                                Initiate request for PR
                            </Button>
                           ) }
                        </div>
                        {
                            subData &&
                            Array.isArray(subData) &&
                            (
                                subData.length>0 ?
                                    <PermanentReplacementListForModule
                                        data={subData}
                                    />
                                    :
                                    <EmptyListPlaceholder
                                        message='No Permanent Replacement Found'
                                    />
                            )
                        }
                        {openInitiatePRDialog && (
                            <InitiatePRRequestDialog
                                open={openInitiatePRDialog}
                                onClose={() => setOpenInitiatePRDialog(false)}
                                subscriptionDetails={cookSubscriptionDetails}
                                reasonSubReasonData={prReasonSubreasonData}
                                handleContinueClicked={handleInitiatePRContinueClicked}
                            />
                        )}
                        {openPrSelectDateSlotDialog && (
                            <PRSelectDateSlotDialog
                                open={openPrSelectDateSlotDialog}
                                onClose={() => setOpenPrSelectDateSlotDialog(false)}
                                bundleDetails={record as UserBundlesResponse}
                                subscriptionDetails={subscriptionDetails}
                                gracePeriodDetails={gracePeriodDetails}
                                permanentReplacementDetails={prData}
                                cookSubscriptionDetails={cookSubscriptionDetails}
                                handleContinueClicked={handleDateSlotContinueClicked}
                            />
                        )}
                        {openPrTrialCreatedSuccessDialog && (
                            <PRTrialCreateSuccessDialog
                                open={openPrTrialCreatedSuccessDialog}
                                onClose={()=> {
                                    refreshListener();
                                    setOpenPrTrialCreatedSuccessDialog(false);
                                }}
                            />
                        )}
                    </div>
            }
        </div>
    );
};
export default UserBundlePrTab;
