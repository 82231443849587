import { Datagrid, DateField, List, SelectInput, ShowButton, TextField, TextInput } from "react-admin";
import { useAppSelector } from "../../store/hooks";
import ResourceSelectDialogButton from "../Common/ResourceSelectDialogButton";


export const CityList = () => {
    const isCustomSelectOpen = useAppSelector((state) => state.customSelect.value.open);

    const cityFilters = [
        <TextInput sx={{ width: "150px" }} label="City Name" source="name" alwaysOn />,
        <SelectInput
            label="Serviceable"
            source="isCurrentlyServing"
            emptyText="Clear Filter"
            alwaysOn
            sx={{ width: "180px" }}
            choices={['YES','NO'].map((key) => {
                return {
                id: key,
                name: key,
                };
            })}
        />,
    ];

    return (
        <List filters={cityFilters}>
            <Datagrid bulkActionButtons={false}>
                {isCustomSelectOpen && <ResourceSelectDialogButton />}
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="isCurrentlyServing" />
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
                <ShowButton />
            </Datagrid>
        </List>
    );
};
